import { createContext, useContext, useMemo } from "react";
// import { useNavigate } from "react-router";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("tokens", null);
  // const navigate = useNavigate();

  const user = useMemo(() => {
    if (!token) return { role: "public" };
    const res = JSON.parse(atob(token.accessToken.split(".")[1]));
    return { role: "admin" };
  }, [token]);

  const login = (res) => setToken(res);
  const registration = (res) => setToken(res);
  const logout = () => setToken(null);

  const values = { user, login, registration, logout };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
