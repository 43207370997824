import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signinSchema } from "schemas";

import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import api from "services";
import { useAuth } from "hooks/useAuth";

const formData = [
  {
    type: "text",
    fullWidth: true,
    name: "email",
    // fieldProps: { inputProps: { maxLength: 60 } },
    label: "Email",
  },
  {
    type: "password",
    fullWidth: true,
    name: "password",
    // fieldProps: { inputProps: { maxLength: 60 } },
    label: "Password",
  },
];
const defaultValues = { email: "", password: "" };

const SignIn = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
    resolver: yupResolver(signinSchema),
  });

  const disabledBtn = !isValid || loading;

  const onSubmit = (data) => {
    setLoading(true);
    error && setError(null);

    api
      .login("/signin", data)
      .then((data) => login(data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        pt: 10,
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        mb={4}
        fontWeight={700}
        align="center"
      >
        Sign in
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} mb={4}>
          {formData.map(({ type, name, label, fieldProps }) => (
            <Grid key={name} item xs={12}>
              <Controller
                name={name}
                control={control}
                render={({ field }) => (
                  <TextField
                    type={type}
                    label={label}
                    variant="filled"
                    fullWidth
                    error={!!errors[name]}
                    helperText={errors[name] && errors[name].message}
                    {...fieldProps}
                    {...field}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>

        {error && (
          <Alert
            severity="error"
            variant="outlined"
            sx={{ width: "100%", mb: 2 }}
          >
            {error}
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          size="large"
          color="secondary"
          sx={{ textTransform: "capitalize", mb: "2rem" }}
          disabled={disabledBtn}
          startIcon={loading && <CircularProgress size={20} />}
        >
          submit
        </Button>
      </form>
    </Container>
  );
};

export default SignIn;
