import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(5, "minimum 5 characters allowed")
    .max(60, "maximum 60 charac ters allowed"),
  category: yup.string().required("Category is required"),
  tags: yup.array().required("Tags is required").min(1, "Tags is required"),
  projects: yup
    .array()
    .required("Project is required")
    .min(1, "Project is required"),
  description: yup
    .string()
    .required("Description is required")
    .min(20, "minimum 20 characters allowed")
    .max(125, "maximum 125 characters allowed"),
  videoPreviewLink: yup
    .string()
    .required("Link to video is required")
    .min(10, "minimum 10 characters allowed")
    .max(100, "maximum 100 characters allowed"),
  salary: yup
    .string()
    .required("Salary is required"),
    // .matches(/^\d+$/, "The field should have digits only"),
  // min
  location: yup.string().required("Location is required"),
  type: yup.string().required("Job is required"),
  shift: yup.string().required("Shift is required"),
  experience: yup.string().required("Experience is required"),
  about: yup
    .string()
    .required("About the role is required")
    .min(50, "minimum 50 characters allowed")
    .max(500, "maximum 500 characters allowed"),
  responsibilities: yup.array().of(
    yup.object().shape({
      responsibility: yup
        .string()
        .trim()
        .required(`Responsability is required`)
        .min(20, "minimum 20 characters allowed")
        .max(170, "maximum 170 characters allowed"),
    })
  ),
});

export const signinSchema = yup.object().shape({
  email: yup.string().required("Email is required").email("Invalid email"),
  password: yup.string().required("Password is required"),
});
