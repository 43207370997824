import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Header from "components/Header";

const PrivateLayout = () => {
  return (
    <>
      <Header />
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default PrivateLayout;
