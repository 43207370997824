import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button, Link } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useAuth } from "hooks/useAuth";

const navigate = [
  { link: "/", label: "Home" },
  { link: "/cv-storage", label: "CV Storage" },
];

function Header() {
  const { logout } = useAuth();

  return (
    <AppBar color="primary" position="sticky" sx={{ mb: 5 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            component="div"
            noWrap
            sx={{
              display: { xs: "none", md: "flex" },
              mr: 2,
              fontWeight: 700,
            }}
          >
            D42 admin
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: {
                xs: "flex",
                justifyContent: "flex-end",
                gap: "1rem",
                marginRight: "3rem",
              },
            }}
          >
            {navigate.map(({ link, label }) => (
              <Link
                key={link}
                component={NavLink}
                to={link}
                underline="none"
                sx={{
                  display: "block",
                  padding: "0.2rem 0.4rem",
                  color: "#fff",
                  borderRadius: "0.2rem",
                  transition: "0.3s",
                  ":hover": {
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                {label}
              </Link>
            ))}
          </Box>

          <Button
            variant="outlined"
            color="secondary"
            // sx={{ textTransform: "capitalize" }}
            // size="small"
            onClick={logout}
          >
            logout
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
