class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.refreshToken = false;
    this.ERROR_MESSAGE = "Something went wrong. Please try again";
  }

  // default method
  async getToken() {
    const { accessToken, refreshToken } = JSON.parse(
      localStorage.getItem("tokens")
    );
    const user = JSON.parse(atob(accessToken.split(".")[1]));
    const currentTimestamp = new Date().getTime() / 1000;
    const tokenIsExpired = user.exp < currentTimestamp;
    if (tokenIsExpired) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 10000);
      // this.refreshToken = true;
      const res = await fetch(`${this.baseUrl}/users/refresh`, {
        method: "POST",
        headers: {
          Authorization: refreshToken,
        },
        signal: controller.signal,
      });

      // this.refreshToken = false;
      clearTimeout(timeoutId);
      if (res.status === 400) {
        localStorage.setItem("tokens", null);
        // window.location.reload();
        window.location.replace("/signin");
      }
      if (!res.ok) throw res.status;
      const tokens = await res.json();
      localStorage.setItem("tokens", JSON.stringify(tokens));
      return tokens.accessToken;
    }
    return accessToken;
  }

  async #fetch(params) {
    const { role = "private", endpoint, method, payload = false } = params;
    try {
      const api = `${this.baseUrl}${endpoint}`;
      const options = {};
      options.method = method;
      if (role === "private") {
        const accessToken = await this.getToken();
        options.headers = {
          ...options.headers,
          Authorization: `${accessToken}`,
        };
      }
      const methodsListSend = ["POST", "PATCH", "PUT", "DELETE"];
      if (methodsListSend.includes(method)) {
        if (payload) {
          options.headers = {
            ...options.headers,
            "Content-Type": "application/json",
          };
          options.body = JSON.stringify(payload);
        }
      }

      const response = await fetch(api, options);
      if (!response.ok) throw response;
      return [null, response];
    } catch (error) {
      return [error, null];
    }
  }

  async login(endpoint, values) {
    const [error, data] = await this.#fetch({
      role: "public",
      endpoint: `/users${endpoint}`,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) {
        throw "The email address or password is incorrect. Please retry...";
      }
      throw this.ERROR_MESSAGE;
    }
    return await data.json();
  }

  async createVacancy(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "POST",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }

  async updateVacancy(endpoint, values) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "PATCH",
      payload: values,
    });
    if (error) {
      if (error.status === 400) throw await this.#getInvalidFields(error);
      throw this.ERROR_MESSAGE;
    }
    return data.statusText;
  }
  async removeVacancy(endpoint) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "Delete",
    });
    if (error) throw this.ERROR_MESSAGE;
    return data.statusText;
  }

  async get(endpoint) {
    const [error, data] = await this.#fetch({
      endpoint: endpoint,
      method: "GET",
    });
    if (error) throw this.ERROR_MESSAGE;
    return await data.json();
  }

  async #getInvalidFields(error) {
    const { invalidFields, msg } = await error.json();
    if (invalidFields) return invalidFields;
    if (msg) return msg;
  }
}

export default new Api(process.env.REACT_APP_API_DOMAIN || "http://192.168.1.189:3020");
