import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import PrivateLayout from "layouts/PrivateLayout";

import SignIn from "pages/Auth/SignIn";
const HomePage = lazy(() => import("pages/HomePage"));
const CVStoragePage = lazy(() => import("pages/CVStoragePage"));
const VacancyPage = lazy(() => import("pages/VacancyPage"));

// Admin
const adminRoutes = [
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: "cv-storage",
        element: <CVStoragePage />,
      },
      // {
      //   path: "vacancy",
      //   element: <VacancyPage />,
      // },
      {
        path: "vacancy",
        children: [
          { index: true, element: <VacancyPage /> },
          { path: ":vacancyId", element: <VacancyPage /> },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];
// PUBLIC
const publicRoutes = [
  {
    path: "/signin",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/signin" />,
  },
];

const ROLES_ROUTES = {
  admin: adminRoutes,
  public: publicRoutes,
};

export const getRoutes = (role) => ROLES_ROUTES[role] ?? publicRoutes;
