import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#09090A",
    },
    secondary: {
      // main: "#a512eb",
      main: "#4285f4",
    },
  },
});

export { theme };
