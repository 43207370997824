import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "hooks/useAuth";

import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import CssBaseline from "@mui/material/CssBaseline";

import App from "./App";

import "./global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
