import { useRoutes } from "react-router";
import { getRoutes } from "routes";
import { useAuth } from "hooks/useAuth";

const App = () => {
  const { user } = useAuth();
  return useRoutes(getRoutes(user.role));
};

export default App;
